import {IInformationMenuProvider, Menu} from "@unifly-aero/web-common/src/layout/sidebar/information-menu-provider";
import {AppConfig} from "@unifly-aero/web-common/src/config/AppConfig";
import { DialogService } from "aurelia-dialog";
import { LicenseAgreementService } from "@unifly-aero/web-common/src/services/LicenseAgreementService";
import { I18N } from "aurelia-i18n";
import {PLATFORM, autoinject} from 'aurelia-framework';
import { LicenseAgreement } from "@unifly-aero/web-common/src/model/LicenseAgreement";
import { PolicyType } from "@unifly-aero/web-common/src/services/PolicyType";
import { LicenseAgreementModalModel, LicenseAgreementModal } from "@unifly-aero/web-common/src/services/LicenseAgreement-modal";
import { Configuration } from "@unifly-aero/web-common/src/config/Configuration";
import {Session} from "@unifly-aero/web-common/src/config/Session";

export type Weblink = {
    name: string;
    url: string
}

@autoinject
export class DfsInformationMenuProvider extends IInformationMenuProvider {

    webLinks: Array<Weblink>;

    constructor(
        private brandSettings: AppConfig,
        private dialogService: DialogService,
        private licenseAgreementService: LicenseAgreementService,
        private i18n: I18N,
        private configuration: Configuration,
        private session: Session
    ) {
        super();
        // @ts-ignore
        this.webLinks = configuration.webLinks;
    }

    protected provideHelpMenuGroup() {
        return {
            title: 'information_menu.help',
            items: [
                {
                    label: 'information_menu.release_info',
                    action: () => { this.openReleaseInfoDialog(); }
                }
            ]
        };
    }

    protected provideLegalMenuGroup() {
        return {
            title: 'information_menu.legal',
            items: [
                {
                    label: 'routes.license_agreement',
                    action: () => { this.openTermsAndConditions(); }
                },
                {
                    label: 'routes.privacy_policy',
                    action: () => { this.openPrivacyPolicy(); }
                },
                {
                    label: 'routes.map_data',
                    action: () => { this.openMapdata(); }
                },
                {
                    label: 'routes.imprint',
                    action: () => { this.openImprint(); }
                }
            ]
        }
    }

    public provideMenu(): Menu {
        return [
            this.provideHelpMenuGroup(),
            this.provideLegalMenuGroup()
        ];
    }

    protected async openLicenceAgreementDialog(policyType: PolicyType): Promise<void> {
        let licenseAgreement: LicenseAgreement | null = null;

        if (policyType === PolicyType.LICENSE_AGREEMENT) {
            licenseAgreement = await this.licenseAgreementService.getCurrentLicenseAgreement();
        } else if (policyType === PolicyType.PRIVACY_POLICY) {
            licenseAgreement = await this.licenseAgreementService.getCurrentPolicy(PolicyType.PRIVACY_POLICY)
        } else if (policyType === PolicyType.DATA_POLICY) {
            licenseAgreement = await this.licenseAgreementService.getCurrentPolicy(PolicyType.DATA_POLICY)
        }

        if (!licenseAgreement) { return; }

        const model: LicenseAgreementModalModel = {
            licenseAgreement,
            readOnlyMode: true,
            hasAgreed: true
        };

        this.dialogService
            .open({ model, viewModel: LicenseAgreementModal })
    }

    protected async openWeblink(name: string): Promise<void> {
        let url:string | undefined = this.webLinks.find(wl => wl.name === name)?.url;
        if (url) window.open(url, '_blank');

    }

    protected openPrivacyPolicy(): void {
        this.openLicenceAgreementDialog(PolicyType.PRIVACY_POLICY);
    }

    protected openTermsAndConditions(): void {
        this.openLicenceAgreementDialog(PolicyType.LICENSE_AGREEMENT)
    }

    protected openReleaseInfoDialog(): void {
        this.dialogService.open({viewModel: PLATFORM.moduleName('@unifly-aero/web-common/src/widgets/release-info/release-info-dialog')});
    }

    protected openMapdata(): void {
        this.openWeblink("map_data")
    }

    protected openImprint(): void {
        this.openWeblink("imprint." + this.session.userPreferences.locale)
    }
}

