import {autoinject} from "aurelia-framework";
import {BasicPainter} from "@unifly-aero/web-common/src/unifly-luciad/basic-painter";
import {UniflyMap} from "@unifly-aero/web-common/src/unifly-luciad/unifly-map";
import {AirspaceLayerFactory} from "@unifly-aero/web-common/src/unifly-luciad/layers/airspace-layer-factory";
import {UasZoneDetails} from "@unifly-aero/web-common/src/unifly-luciad/layers/detail-view/uas-zone-details";
import {AirportDetails} from "@unifly-aero/web-common/src/unifly-luciad/layers/detail-view/airport-details";
import {UasZoneLayerFactory} from "@unifly-aero/web-common/src/unifly-luciad/layers/uas-zone-layer-factory";
import {MapLayersFactory} from "@unifly-aero/web-common/src/unifly-luciad/layers/group/map-layers-factory";
import {LegendOptionsSubLayer} from "@unifly-aero/web-common/src/unifly-luciad/types";
import {TileLayerFactory} from "@unifly-aero/web-common/src/unifly-luciad/layers/tiles/TileLayerFactory"
import {NotamLayerFactory} from "@unifly-aero/web-common/src/unifly-luciad/layers/notam-layer-factory"
import {NotamDetails} from "@unifly-aero/web-common/src/unifly-luciad/layers/detail-view/notam-details";
import {GeoZoneValidityActiveStatus} from "@unifly-aero/web-common/src/utils/FilterAndStoreFactory";

@autoinject
export class dfsMapLayersFactory implements MapLayersFactory {

    private map: UniflyMap;

    constructor(
        private airspaceLayerFactory: AirspaceLayerFactory,
        private uasZoneLayerFactory: UasZoneLayerFactory,
        private notamLayerFactory: NotamLayerFactory
    ) {
    }

    public configureMapLayers(map: UniflyMap): void {
        this.map = map;
        this.setupLayers();
    }

    private setupLayers() {
        this.tileLayer(this.map, "dfs_tiles_administrative_boundary", "administrative_boundary", "rgba(246, 223, 119, 0.60)", "rgba(246, 223, 119, 0.15)");
        this.tileLayer(this.map, "dfs_tiles_prison", "prison", "rgba(120,153,207, 0.60)", "rgba(120,153,207, 0.15)");
        this.tileLayer(this.map, "dfs_tiles_powerplant", "powerplant", "rgba(120,153,207, 0.60)", "rgba(120,153,207, 0.15)");
        this.tileLayer(this.map, "dfs_tiles_federal", "federal", "rgba(120,153,207, 0.60)", "rgba(120,153,207, 0.15)");
        this.tileLayer(this.map, "dfs_tiles_embassy", "embassy", "rgba(120,153,207, 0.60)", "rgba(120,153,207, 0.15)");
        this.tileLayer(this.map, "dfs_tiles_police", "police", "rgba(120,153,207, 0.60)", "rgba(120,153,207, 0.15)");
        this.tileLayer(this.map, "dfs_tiles_hospital", "hospital", "rgba(120,153,207, 0.60)", "rgba(120,153,207, 0.15)");
        this.tileLayer(this.map, "dfs_tiles_labo", "labo", "rgba(120,153,207, 0.60)", "rgba(120,153,207, 0.15)");
        this.tileLayer(this.map, "dfs_tiles_industry", "industry", "rgba(120,153,207, 0.60)", "rgba(120,153,207, 0.15)");
        this.tileLayer(this.map, "dfs_tiles_powerline", "powerline", "rgba(120,153,207, 0.60)","rgba(120,153,207, 0.15)");
        this.tileLayer(this.map, "dfs_tiles_wind_turbine", "wind_turbine", "rgba(120,153,207, 0.60)", "rgba(120,153,207, 0.15)");
        this.tileLayer(this.map, "dfs_tiles_nationalpark", "nature", "rgba(0, 164, 108, 0.60)", "rgba(0,164,108,0.15)");
        this.tileLayer(this.map, "dfs_tiles_military", "military", "rgba(206, 206, 206, 0.60)", "rgba(206, 206, 206, 0.15)");
        this.tileLayer(this.map, "dfs_tiles_populatedarea", "populatedarea", "rgba(229, 144, 79, 0.60)", "rgba(229, 144, 79, 0.15)");
        this.tileLayer(this.map, "dfs_tiles_wateractivity", "wateractivity", "rgba(229, 144, 79, 0.60)", "rgba(229, 144, 79, 0.15)");

        this.layer(this.map, [
                "AIRPORT_CIVIL",
                "AIRPORT_MILITARY",
                "AIRPORT_AF_MIL_CIVIL",
                "AIRPORT_CIVIL_HELIPORT",
                "AIRPORT_MILITARY_HELIPORT",
                "AIRPORT_LIGHT_AIRCRAFT",
                "AIRPORT_INTL_APT",
                "AIRPORT_GLIDING",
                "AIRPORT_GLIDING_INFO",
                "AIRPORT_HANG_GLIDING_INFO",
                "AIRPORT_LIGHT_AIRCRAFT_INFO",
                "AIRPORT_MODEL_FLYING_INFO",
                "AIRPORT_OTHER_HELIPORT_INFO",
                "AIRPORT_OTHER_INFO",
                "AIRPORT_GLIDING_100",
                "AIRPORT_LIGHT_AIRCRAFT_100",
                "AIRPORT_OTHER_HELIPORT_100"
            ],
            "airports", AirportDetails);
        this.layer(this.map, ["AIRSPACE_CTR"], "control_zone");
        this.layer(this.map, ["AIRSPACE_RMZ"], "rmz");
        this.layer(this.map, ["AIRSPACE_TMZ"], "tmz");
        this.layer(this.map, ["AIRSPACE_DANGER"], "military_danger_area");
        this.layer(this.map, ["AIRSPACE_RESTRICTED"], "restricted_area");
        this.layer(this.map, ["TEMPORARY-NO-FLY-ZONE"], "temporary_no_fly_zone");

        this.notamlayer(this.map, "Notams");
        this.map.mapLayersLoaded();

    }

    private notamlayer(parent, label: string) {
        parent.addLayer(this.notamLayerFactory, {
            defaultVisible: false,
            painter: new BasicPainter({
                strokeColor: "rgba(120,153,207, 0.60)",
                fillColor: "rgba(120,153,207, 0.15)"
            })
        }).withLegend({
            label: label,
            strokeColorLegend: "rgba(120,153,207, 0.5)",
            fillColorLegend: "rgba(120,153,207, 0.4)"
        }).withView(NotamDetails);
    }

    private layer(parent, types:string[], label: string, view: new(...any) => any = UasZoneDetails) {
        parent.addLayer(this.uasZoneLayerFactory, {
            defaultVisible: false,
            painter: new BasicPainter({
                strokeColor: "rgba(237,132,134, 0.60)",
                fillColor: "rgba(237,132,134, 0.15)"
            }),
            filterTypes: types,
            windowActiveStatus: GeoZoneValidityActiveStatus.ACTIVE_WITHIN_WINDOW
        }).withLegend({
            label: label,
            strokeColorLegend: "rgba(237,132,134, 0.5)",
            fillColorLegend: "rgba(237,132,134, 0.4)"
        }).withView(view);
    }

    private tileLayer(parent, type: string, label: string, strokeColor: string, fillColor: string, sublayers?: LegendOptionsSubLayer[]) {
        let tileLayerFactory = new TileLayerFactory(type);
        parent.addLayer(tileLayerFactory,  { defaultVisible: false }).withLegend({
            label: "dfs." + label,
            strokeColorLegend: strokeColor,
            fillColorLegend: fillColor,
            subLayers: sublayers
        });
    }
}
