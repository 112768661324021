import '@unifly-aero/proj-common/src/styles/theme.scss';

import { Aurelia } from 'aurelia-framework';
import { bootstrap } from 'aurelia-bootstrapper';
import { configure as configurePortal, start as startPortal } from '@unifly-aero/web-portal/src/main';
import { registerMapLayersFactory,registerInformationMenuProvider } from '@unifly-aero/web-common/src/main';
import AppConfiguration from './config/AppConfiguration';
import {dfsMapLayersFactory} from "../../proj-common/src/layers/dfs-map-layers-factory";
import {DfsInformationMenuProvider} from "../../proj-common/src/layout/sidebar/dfs-information-menu";
import {CallSignProvider} from "@unifly-aero/web-common/src/tracking/painters/renderers/CallSignProvider";
import {IdentificationProvider} from "@unifly-aero/web-common/src/tracking/painters/renderers/IdentificationProvider";

export function configure(aurelia: Aurelia) {
    configurePortal(aurelia, AppConfiguration);
    startPortal(aurelia);
    registerMapLayersFactory(aurelia.container, dfsMapLayersFactory);
    registerInformationMenuProvider(aurelia.container, DfsInformationMenuProvider);
    aurelia.container.registerSingleton(CallSignProvider, IdentificationProvider);
}

bootstrap(configure);
