import { AppConfig } from '@unifly-aero/web-common/src/config/AppConfig';
import PortalAppConfiguration from '@unifly-aero/web-portal/src/config/AppConfiguration';
import CommonAppConfiguration from '@unifly-aero/proj-common/src/config/AppConfiguration';
import merge from 'lodash.merge';

const AppConfiguration: AppConfig = merge<AppConfig, DeepPartial<AppConfig>, DeepPartial<AppConfig>>(CommonAppConfiguration, PortalAppConfiguration, {
    i18n: {
        locales: ['de-DE','en']
    },
    clientId: 'DFSUTMPortal',
    clientSecret: ''
});

export default AppConfiguration;
